<template>
  <div class="login">
    <div class="logo">
      <h2>云游青海</h2>
    </div>
    <div class="submit">
      <nut-button shape="circle" class="btn" @click="submit">
        <img src="../assets/images/weixin.png" alt="" />
        <span>微信登录</span>
      </nut-button>
    </div>
    <p class="but">
      <button @click="tourist">游客模式</button>
    </p>
    <p class="user">登录注册即表示同意<a href="#">《用户隐私协议》</a></p>
  </div>
</template>

<script>
import wechatAuth from "@/tool/wechatAuth.js";
export default {
  mounted() {
    // this.getCode();
  },
  methods: {
    submit() {
      this.$axios.post("/wx/codeUrl").then((res) => {
        let lost = res.msg;
        console.log(lost,'123123');
        window.location.href = lost;
      });
    },
    tourist() {
      this.$router.push("/");
    },
    //  处理codess
    getCode() {
      const code = location.href.split("?")[1];
      // console.log(location.href);
      if (!code) return {};
      const obj = {};
      code.split("&").forEach((item) => {
        const arr = item.split("=");
        obj[arr[0]] = arr[1];
      });
      return obj;
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  height: 92vh;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  .logo {
    margin: 3rem auto;
    width: 110px;
    height: 110px;
    border: 1px solid #b2d000;
    border-radius: 24px;
    background: url("../assets/images/logo.png") no-repeat center center;
    background-size: 100% 100%;
    h2 {
      text-align: center;
      margin-top: 2.4rem;
      font-size: 0.5rem;
      font-weight: 400;
      color: #000000;
    }
  }
  .submit {
    width: 5rem;
    margin: 0 auto;
    .btn {
      width: 100%;
      font-size: 0.3rem;
      background: #53c834;
    }
    img {
      margin-right: 4px;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  .but {
    margin-top: 0.6rem;
    text-align: center;
    button {
      font-size: 0.3rem;
      color: #53c834;
    }
  }
  .user {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    text-align: center;
    color: #686868;
    a {
      color: #333;
    }
  }
}
</style>